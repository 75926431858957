import request from "./base";

export function updateWalletAddress(data) {
  return request.post("/wallet/update", data);
}

export function getAddress() {
  return request.get("/wallet");
}

export function sendVerificationCode() {
  return request.get("/wallet/send-code");
}

export function withdraw(data) {
  return request.post("/wallet/withdraw", data);
}
export function withdrawFIL(data) {
  return request.post("/wallet/withdraw-fil", data);
}
export function withdrawCRU(data) {
  return request.post("/wallet/withdraw-cru", data);
}
