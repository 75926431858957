<template>
  <Layout style="min-height: 100vh">
    <AppHeader />
    <Content class="container">
      <p class="backToHome"><Button @click="backToHome">返回首页</Button></p>
      <h2 class="title">钱包地址</h2>
      <Form class="form">
        <FormItem>
          <p>BZZ 钱包地址</p>
          <Input v-model="address" placeholder="BZZ 钱包地址">
            <Icon type="ios-key" slot="prepend"></Icon>
          </Input>
          <template v-if="pool == 'ming-pool'">
            <p>FIL 钱包地址</p>
            <Input v-model="addressFIL" placeholder="FIL 钱包地址">
              <Icon type="ios-key" slot="prepend"></Icon>
            </Input>
          </template>
          <template v-if="pool != 'jing-pool'">
            <p>CRU 钱包地址(请输入Crust网络地址，不支持ERC20网络地址)</p>
            <Input v-model="addressCRU" placeholder="CRU 钱包地址">
              <Icon type="ios-key" slot="prepend"></Icon>
            </Input>
          </template>
        </FormItem>
        <FormItem>
          <div class="verification-code">
            <Input v-model="code" placeholder="短信验证码">
              <Icon type="ios-call" slot="prepend"></Icon>
            </Input>
            <Button
              class="send-code-button"
              :loading="isSendingCode"
              type="primary"
              @click="sendVerificationCode"
              >发送验证码</Button
            >
          </div>
        </FormItem>
        <FormItem>
          <div class="button-container">
            <Button
              type="primary"
              size="large"
              @click="updateAddress"
              :loading="isLoading"
            >
              确认
            </Button>
          </div>
        </FormItem>
      </Form>
    </Content>
  </Layout>
</template>

<script>
import {
  Layout,
  FormItem,
  Form,
  Input,
  Icon,
  Button,
  Message,
  Content,
} from "view-design";
import AppHeader from "../components/AppHeader";
import {
  getAddress,
  sendVerificationCode,
  updateWalletAddress,
} from "../service/wallet";

export default {
  name: "WalletBinding",
  components: {
    Layout,
    AppHeader,
    FormItem,
    Form,
    Input,
    Icon,
    Button,
    Content,
  },
  data() {
    return {
      address: "",
      addressFIL: "",
      addressCRU: "",
      isLoading: false,
      code: "",
      pool: "",
      isSendingCode: false,
      countdown: 0,
    };
  },
  async mounted() {
    try {
      const result = await getAddress();
      this.address = result.data.address;
      this.addressFIL = result.data.addressFIL;
      this.addressCRU = result.data.addressCRU;
      this.pool = result.data.pool;
    } catch (error) {
      console.error(error);
      Message.error(error.response.data.message);
    }
  },
  methods: {
    backToHome() {
      this.$router.push("/");
    },
    async sendVerificationCode() {
      this.isSendingCode = true;
      try {
        await sendVerificationCode();
        Message.success("发送成功");
      } catch (error) {
        console.error(error);
        Message.error(error.response.data.message);
      }
      this.isSendingCode = false;
    },
    async updateAddress() {
      if (!this.address && !this.addressFIL && !this.addressCRU) {
        return Message.error("请输入钱包地址");
      }
      if (!this.code) {
        return Message.error("请输入验证码");
      }
      this.isLoading = true;
      try {
        const data = {
          address: this.address,
          addressFIL: this.addressFIL,
          addressCRU: this.addressCRU,
          code: this.code,
        };
        let ret = await updateWalletAddress(data);
        this.code = "";
        Message.success({
          content: ret.data.message,
          duration: 10,
          closable: true,
        });
      } catch (error) {
        console.error(error);
        Message.error(error.response.data.message);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px 40px;
  margin: 0 auto;
}

.title {
  margin-top: 20px;
  margin-bottom: 25px;
  text-align: center;
}

.button-container {
  text-align: center;
}
.verification-code {
  display: flex;
}
.send-code-button {
  display: block;
  margin-left: 20px;
}
.backToHome {
  margin-top: 30px;
}
</style>
